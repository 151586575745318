body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI',
    'Helvetica Neue', HelveticaNeue, YuGothic, 'Yu Gothic Medium', 'Yu Gothic',
    Verdana, Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --bold-font-family: -apple-system, BlinkMacSystemFont, Roboto,
    'Segoe UI semibold', 'Helvetica Neue', HelveticaNeue, YuGothic, 'Yu Gothic',
    'Segoe UI', Verdana, Meiryo, sans-serif;
}

b, strong, .bold {
  font-family: var(--bold-font-family);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
