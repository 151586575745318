html {
  --orange: #eb6100;
  --light-orange: #fbe6da;
  --light: #eeffff;
  --white: #ffffff;
  --dark: #333333;
  --translucent: rgba(0, 0, 0, 0.3);

  background-color: var(--light-orange);
  color: var(--dark);
  font-size: 14px;
}

a {
  color: var(--orange);
  text-decoration: none;
}

strong {
  text-decoration: underline;
}

h1 {
  background-image: url('./images/h1_melody.png');
  background-repeat: no-repeat;
  text-indent: 50px;
  color: #444444;
  font-size: 24px;
  line-height: 45px;
  padding-bottom: 5px;
}

h2 {
  border-left: 20px var(--orange) solid;
  font-size: 18px;
  line-height: 36px;
  padding-left: 10px;
  margin-top: 25px;
}

h3 {
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: solid 2px var(--orange);
}

h4 {
  font-size: 14px;
  font-weight: bold;
  font-family: var(--bold-font-family);
}

table {
  border: solid 2px var(--orange);
  border-collapse: collapse;
}

th {
  color: var(--white);
  background-color: var(--orange);
  font-size: 16px;
  font-weight: bold;
  font-family: var(--bold-font-family);
}

td {
  border: solid 1px var(--orange);
  background-color: var(--white);
}

table.unset {
  border: unset;
}

table.unset td {
  border: unset;
  background-color: unset;
}
